import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "@/views/HomeView.vue"
import FeedbackSummary from "@/views/FeedbackSummaryView.vue"
import NewFeedback from "@/views/NewFeedbackView.vue"
import StrypeHistory from "@/views/StrypeHistoryView.vue"
import StrypeFAQ from "@/views/StrypeFAQView.vue"
import Policy from "@/views/PolicyView.vue"
import Events from "@/views/EventsView.vue"
import { beforeUnloadEvent } from "@/helpers/misc"
import store from "@/store/store"

/** Prepare the router **/
const homeRouteName = "home"
export const feedbacksRoutePath = "/feedback"
const feedbacksRouteName = "feedbackSummary"
export const newFeedbackRoutePath = "/newfeedback"
const newFeedbackRouteName = "newFeedback"
export const strypeHistoryRoutePath = "/history"
const strypeHistoryRouteName = "history"
export const strypeFAQRoutePath = "/FAQ"
const strypeFAQRouteName = "FAQ"
export const policyRoutePath = "/policy"
const policyRoutePathName = "policy"
export const eventsRoutePath = "/events"
const eventsRoutePathName = "events"

const routes = [
    { path: "/", component: Home, name: homeRouteName },
    { path: feedbacksRoutePath, component: FeedbackSummary, name: feedbacksRouteName },
    { path: newFeedbackRoutePath, component: NewFeedback, name: newFeedbackRouteName },
    { path: strypeHistoryRoutePath, component: StrypeHistory, name: strypeHistoryRouteName },
    { path: strypeFAQRoutePath, component: StrypeFAQ, name: strypeFAQRouteName },
    { path: policyRoutePath, component: Policy, name: policyRoutePathName },
    { path: eventsRoutePath, component: Events, name: eventsRoutePathName },
] as RouteRecordRaw[]
/** End router preparation **/

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
})

// Handle the navigation guards
router.beforeEach((to, from) => {
    // Check that wrong paths are redirected to the main page
    if (routes.flatMap((route) => route.name).includes(to.name ?? "")) {
        // When changing view, the page scrolling stays where it was, which makes a weird rendering.
        // Therefore, we force scrolling up to the top of the page to start with a clean presentation during navigation
        window.scrollTo(0, 0)
        // for global guards: we always remove the beforechange event on window before getting to the new page
        window.removeEventListener("beforeunload", beforeUnloadEvent)

        // for the particular case of leaving the "New" view, we check the content hasn't changed and hasn't been valided before allowing the routing
        if (from.name === newFeedbackRouteName && store.getters.getHasPendingChanges()) {
            // if the user leaves the page, we reset the hasPendingChanges flag in the store
            const confirmedCancelChanges = confirm("New feedback has not be saved, do you really want to leave this form?")
            store.commit("setHasPendingChanges", !confirmedCancelChanges)
            // we only break the route if the cancellation has been confirmed
            if (!confirmedCancelChanges) {
                return false
            }
        }

        // for the particular case of entering the feedback page, we set the feedback from the DB unless flagged otherwise
        // (in that latter case, we reset the flag for the next time it might require a DB reload)
        if (to.name === feedbacksRouteName) {
            if (!store.getters.getFetchFeedbackFromDB()) {
                store.commit("setFetchFeedbackFromDB", true)
            }
            else {
                store.commit("setFeedbacks")
            }
        }
    }
    else {
        return "/"
    }
})

export default router
