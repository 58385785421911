<template>
  <section>
    <div>
      <router-link
        :to="feedbackRoute"
      >
        <button
          id="backButton"
          class="btn yellow-styled-button"
          :disabled="isDBSendingFeedback"
        >
          Back
          <i
            :class="{'fas fa-arrow-circle-left': true, 'disabled': isDBSendingFeedback}"
          />
        </button>
      </router-link>
    </div>
    <NewFeedback />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import store from "@/store/store"
import NewFeedback from "@/components/NewFeedback.vue"
import { beforeUnloadEvent } from "@/helpers/misc"
import { feedbacksRoutePath } from "@/router/router"
import { DBOperationType } from "@/helpers/types"

export default defineComponent({
    name: "NewFeedbackView",
    store,

    components: {
        NewFeedback,
    },

    computed: {
        feedbackRoute (): string {
            return feedbacksRoutePath
        },

        isDBSendingFeedback (): boolean {
            return store.getters.getDBOperation().type === DBOperationType.NewFeedback
        },
    },

    created () {
        window.addEventListener("beforeunload", beforeUnloadEvent)
    },
})
</script>

<style>
#backButton {
    margin-bottom: 5px;
}

#backButton i.disabled {
    pointer-events: none;
}
</style>
