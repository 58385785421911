import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  id: "strypeFAQTOCSection",
  class: "vflex"
}
const _hoisted_2 = { class: "FAQ-CAT" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "qt-title" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { id: "strypeFAQQtSection" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Strype Frequently Asked Questions", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "FAQ-TOC" }, "Content", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqCats, (cat) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cat.id+'TOC'
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("a", {
              href: '#'+cat.id
            }, _toDisplayString(cat.title), 9, _hoisted_3)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cat.qts, (catQt) => {
            return (_openBlock(), _createElementBlock("div", {
              key: catQt.id+'TOC'
            }, [
              _createElementVNode("span", _hoisted_4, [
                _createElementVNode("a", {
                  href: '#'+catQt.id
                }, _toDisplayString(catQt.title), 9, _hoisted_5)
              ])
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "FAQ-hr" }, null, -1)),
    _createElementVNode("section", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqCats, (cat) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cat.id
        }, [
          _createElementVNode("span", {
            id: cat.id,
            class: "FAQ-CAT"
          }, _toDisplayString(cat.title), 9, _hoisted_7),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cat.qts, (catQt) => {
            return (_openBlock(), _createElementBlock("div", {
              key: catQt.id
            }, [
              _createElementVNode("span", {
                id: catQt.id,
                class: "qt-title"
              }, _toDisplayString(catQt.title), 9, _hoisted_8),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(catQt.answer, (qtAnswerPart, index) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: catQt.id+'A'+index,
                  innerHTML: qtAnswerPart
                }, null, 8, _hoisted_9))
              }), 128))
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ], 64))
}